const accountSts = [
  {
    value: 1,
    label: '开启'
  },
  {
    value: 0,
    label: '关闭'
  }
];
export { accountSts };
